import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import { Fade, AttentionSeeker, Zoom } from "react-awesome-reveal";
import { MdWavingHand } from "react-icons/md";
import WIPSVG from "~/assets/svg/wip";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            aboutme
          }
    }
`;

const MainText = styled.p`
    font-size: 3em;
    color: #d6d4d4;
    font-family: "Roboto";
    font-weight: 700;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    line-height: 4.25rem;
    padding: 0 0 0 4rem;
    @media (max-width: 1250px) {
      padding: 0;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-flow: column wrap;
    align-items: center;
    padding: 5rem 0;
    height: 100vh;
`;
const Card = styled.div`
  width: 80%;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  background-color: #30303065;
  padding: 3rem;
  font-family: "Roboto";
  line-height: 1.2rem;
  letter-spacing: 0.5px;
`;
const Header = styled.div`
  font-size: 2rem;
  line-height: 2rem;
`;
const Body = styled.div`
`;
const Location = styled.p`
  font-size: .7rem;
  margin-top: -1rem;
`;

const PrivacyPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();

  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Saba Hossein - Term and Condition"
        description="Full Stack Web Developer with over 9 years of experience and with a large set of supporting skills. Experienced in Typescript, Javascript, React, Gatsby, Adobe XD, UX/UI Design, Node.js, HTML, CSS, Styled Components, React Spring, AWS, Imgix, HeadLess CMS, and many more."
        keywords={["saba"]}
        url={props.location.pathname}
      />
      <Container>
        <Card>
          <Header>PRIVACY</Header>
          <Body>
            <p>Visitors to this Website are not tracked, except to produce aggregate statistical data which does not identify individual users. Where cookies are used to provide essential functionality, these are not used to track use of the website or to store personally-identifiable information. Steps have been taken to secure all information collected from unauthorized access, use or disclosure. Please note that this website may include links to websites with different privacy policies.</p>
          </Body>
        </Card>

      </Container>
    </>
  )
}

export default PrivacyPage
